import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import DatePicker from '@/components/DatePicker';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { carFilters, dateFilters } from '@/features/efsParkingPage/consts/keys';
import { downloadXls } from '@/features/efsParkingPage/utils';

import Table from './Table';

const EfsParkingPage = props => {
  const [parkingData, setParkingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const [excelDates, setExcelDates] = useState({ from: null, to: null });

  const { location, classes } = props;

  const loadParkingData = async (sort, filter = {}) => {
    setLoading(true);

    const filters = {
      page,
      size: pageSize,
    };

    try {
      const response = await api.doGetParkingRequest(
        '/parking-session/all-sessions',
        { ...filters, sort, filter },
      );

      setTotalCount(response.total_elements);
      setParkingData(response.content);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onSetPageSize = newPageSize => {
    setPage(0);
    setPageSize(newPageSize);
  };

  const onSetPage = newPage => {
    setPage(newPage);
  };

  const onSetParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filter = [];
    const sort = [];

    filter.push(`discountType:SALES_EFS`);

    for (const param of searchParams) {
      let filterStr = '';

      const name = param[0];
      const value = param[1];
      const filteredName = name.replace('[gte]', '').replace('[lte]', '');

      const isDate = dateFilters.includes(filteredName);
      const isCar = carFilters.includes(filteredName);

      if (name !== 'sort') {
        if (name.indexOf('gte') !== -1) {
          const fieldValue = isDate
            ? moment(new Date(value))
                .utc()
                .format(`YYYYMMDDTHHmmss`)
            : value;

          filterStr = `${filteredName}>${fieldValue}`;
        } else if (name.indexOf('lte') !== -1) {
          const fieldValue = isDate
            ? moment(new Date(value))
                .utc()
                .format(`YYYYMMDDTHHmmss`)
            : value;

          filterStr = `${filteredName}<${fieldValue}`;
        } else if (isCar) {
          filterStr = `${filteredName}~${value}`;
        } else {
          filterStr = `${filteredName}:${value}`;
        }
        filter.push(filterStr);
      } else {
        sort.push(value);
      }
    }

    return {
      sort,
      filter,
    };
  };

  const onDownloadExcel = () => {
    const dateFilter = {
      from: format(new Date(excelDates.from), 'yyyy-MM-dd'),
      to: format(new Date(excelDates.to), 'yyyy-MM-dd'),
    };

    downloadXls({ ...dateFilter });
  };

  useEffect(() => {
    const { sort, filter } = onSetParams();

    loadParkingData(sort, filter);
  }, [pageSize, page, location.search]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Паркинг</PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <div className={classes.buttonsWrapper}>
          <div>
            <DatePicker
              clearable
              label="от"
              className={classes.input}
              format={'PPP'}
              value={excelDates.from}
              onChange={event => {
                setExcelDates(prevState => ({
                  ...prevState,
                  ...{
                    from: event.target.value,
                  },
                }));
              }}
            />
            <DatePicker
              clearable
              label="до"
              className={classes.inputLast}
              format={'PPP'}
              value={excelDates.to}
              onChange={event => {
                setExcelDates(prevState => ({
                  ...prevState,
                  ...{
                    to: event.target.value,
                  },
                }));
              }}
            />
          </div>
          <Button
            variant="outlined"
            color="default"
            size="small"
            className={classes.excelButton}
            disabled={!excelDates.from || !excelDates.to}
            onClick={onDownloadExcel}
          >
            Вывести отчет
          </Button>
        </div>
        <Table
          limit={pageSize}
          items={parkingData}
          isLoading={loading}
          totalCount={totalCount}
          page={page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
      </PageContent>
    </Page>
  );
};

EfsParkingPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default EfsParkingPage;
