import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  EXIT_DATETIME_KEY,
  ID_KEY,
  ORDER_AMOUNT_KEY,
  ORDER_CUSTOMER_ID_KEY,
  ORDER_CUSTOMER_LEVEL_KEY,
  ORDER_DEBT_KEY,
  ORDER_ID_KEY,
  ORDER_STATUS_KEY,
  RECORD_CREATED_KEY,
  VEHICLE_GOV_KEY,
  VEHICLE_GOV_NUMBER_KEY,
  VEHICLE_MODEL_KEY,
} from '@/features/efsParkingPage/consts/keys';

const HeadRow = ({ SortControl, ...props }) => {
  const [sortFields, setSortFields] = useState({
    [ID_KEY]: [{}],
    [ORDER_ID_KEY]: [{}],
    [ORDER_CUSTOMER_ID_KEY]: [{}],
    [ORDER_CUSTOMER_LEVEL_KEY]: [{}],
    [RECORD_CREATED_KEY]: [{}],
    [EXIT_DATETIME_KEY]: [{}],
    [ORDER_STATUS_KEY]: [{}],
    [VEHICLE_GOV_KEY]: [{}],
    [ORDER_DEBT_KEY]: [{}],
    [ORDER_AMOUNT_KEY]: [{}],
  });

  const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  let currentUrlParams = new URLSearchParams(window.location.search);

  const changeFilter = fieldName => {
    Object.keys(initialSortFields).forEach(field => {
      initialSortFields[field] = [{}];
    });

    const changedField = fieldName[0];

    initialSortFields[changedField.field] = [changedField];

    const isVehicle = changedField.field === VEHICLE_GOV_KEY;

    if (isVehicle) {
      const vehicleSort = [
        `${VEHICLE_GOV_NUMBER_KEY},${changedField.direction}`,
        `${VEHICLE_MODEL_KEY},${changedField.direction}`,
      ];

      // Combine two search params
      // First search params - previour params, second - sort params
      currentUrlParams.delete('sort');

      const outputSearchParams = new URLSearchParams();

      for (const [key, val] of currentUrlParams.entries()) {
        outputSearchParams.append(key, val);
      }

      const sortParams = new URLSearchParams(
        vehicleSort.map(param => ['sort', param]),
      );

      for (const [key, val] of sortParams.entries()) {
        outputSearchParams.append(key, val);
      }

      currentUrlParams = outputSearchParams;
      //
    } else if (changedField.direction === 'asc') {
      currentUrlParams.set('sort', `${changedField.field},asc`);
    } else {
      currentUrlParams.set('sort', `${changedField.field},desc`);
    }

    props.history.push({
      pathname: '/efs-parking',
      search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    });

    setSortFields(initialSortFields);
  };

  useEffect(() => {
    const sortList = new URLSearchParams(window.location.search).getAll('sort');

    sortList.map(sort => {
      const sortElem = sort.split(',');

      const fieldName = sortElem[0];
      const fieldDirection = sortElem[1];

      if (
        fieldName === VEHICLE_GOV_NUMBER_KEY ||
        fieldName === VEHICLE_MODEL_KEY
      ) {
        initialSortFields[VEHICLE_GOV_KEY] = [
          {
            field: VEHICLE_GOV_KEY,
            direction: fieldDirection,
          },
        ];
      } else {
        initialSortFields[fieldName] = [
          {
            field: fieldName,
            direction: fieldDirection,
          },
        ];
      }

      return sort;
    });
    setSortFields(initialSortFields);
  }, []);

  return (
    <TableRow>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ID_KEY]}
          field={ID_KEY}
          setSort={changeFilter}
        >
          ID заезда/выезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_ID_KEY]}
          field={ORDER_ID_KEY}
          setSort={changeFilter}
        >
          # заказа
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_CUSTOMER_ID_KEY]}
          field={ORDER_CUSTOMER_ID_KEY}
          setSort={changeFilter}
        >
          ID пользователя
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_CUSTOMER_LEVEL_KEY]}
          field={ORDER_CUSTOMER_LEVEL_KEY}
          setSort={changeFilter}
        >
          Уровень лояльности
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[VEHICLE_GOV_KEY]}
          field={VEHICLE_GOV_KEY}
          setSort={changeFilter}
        >
          Модель и гос. номер авто
        </SortControl>
      </CondenseTableCell>
      {/* <CondenseTableCell>
        <div>Модель и гос. номер авто - временный</div>
      </CondenseTableCell> */}
      <CondenseTableCell>
        <SortControl
          sort={sortFields[RECORD_CREATED_KEY]}
          field={RECORD_CREATED_KEY}
          setSort={changeFilter}
        >
          Дата и время заезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[EXIT_DATETIME_KEY]}
          field={EXIT_DATETIME_KEY}
          setSort={changeFilter}
        >
          Дата и время выезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_AMOUNT_KEY]}
          field={ORDER_AMOUNT_KEY}
          setSort={changeFilter}
        >
          Стоимость заезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_DEBT_KEY]}
          field={ORDER_DEBT_KEY}
          setSort={changeFilter}
        >
          Долг
        </SortControl>
      </CondenseTableCell>
      {/* <CondenseTableCell>
      <div>Способ заезда</div>
    </CondenseTableCell> */}
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_STATUS_KEY]}
          field={ORDER_STATUS_KEY}
          setSort={changeFilter}
        >
          Статус оплаты
        </SortControl>
      </CondenseTableCell>
      {/* <CondenseTableCell>Способ оплаты: активный баланс</CondenseTableCell>*/}
      <CondenseTableCell>Тип льготы</CondenseTableCell>
      <CondenseTableCell>P3</CondenseTableCell>
      <CondenseTableCell style={{ minWidth: 250 }}>
        Вход в EFS
      </CondenseTableCell>
      <CondenseTableCell>Часов проведено на паркинге</CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeadRow;
