import {
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Payment, Refresh } from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import api from '@/api';
import CanView from '@/containers/CanView';
import {
  CALCULATE,
  ID_KEY,
  WITH_BILLING,
} from '@/features/parkingPage/consts/keys';
import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormatExact, formatMoney } from '@/utils/format';

import { canUpdateParking } from '../../permissions';
import SimulateFinish from '../Modal/SimulateFinish';

const loyaltyLevels = {
  1: 'Orange',
  2: 'Black',
  3: 'JetBlack',
};

const paymentStatuses = {
  0: 'Не оплачено',
  1: 'Оплачено',
};

const Row = props => {
  const {
    id,
    customer,
    order,
    record_created,
    exit_datetime,
    building,
    // valet,
    vehicle_model,
    vehicle_gov_number,
    debt_amount,
    // temporary_gov_number,
    // chargedFromEfsBalance,
    discount_type,
    p3_entry,
    p3_entry_at,
    efs_visit,
    efs_visit_at,
    discount_details,
  } = props.item;
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openSimulateFinishDialog = () => {
    setSelectedItem(prevState => ({
      ...prevState,
      id,
    }));
    setIsDialogOpen(!isDialogOpen);
  };

  const closeSimulateFinishDialog = () => {
    setSelectedItem(null);
    setIsDialogOpen(false);
  };

  const handleSimulateFinish = async values => {
    const payload = {
      ...values,
    };

    try {
      await api.doPostParkingRequest(
        `/parking-session/simulate-finish`,
        payload,
      );

      closeSimulateFinishDialog();

      window.parkingData.loadParkingData();
    } catch (error) {
      throw error;
    }
  };

  const getAmountMoney = () => (order.amount ? formatMoney(order.amount) : '-');

  const reloadPaymentValues = {
    [CALCULATE]: true,
    [WITH_BILLING]: true,
    [ID_KEY]: id,
  };

  return (
    <>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{order.id}</TableCell>
        <TableCell>
          <Link to={createUserPageUrl(customer.id)}>{customer.id}</Link>
        </TableCell>
        <TableCell>{loyaltyLevels[customer.level]}</TableCell>
        <TableCell>{building}</TableCell>
        <TableCell>
          {vehicle_model} {vehicle_gov_number}
        </TableCell>
        <TableCell>
          {record_created
            ? dateTimeFormatExact(record_created, 'DD-MM-yyyy HH:mm:ss')
            : '-'}
        </TableCell>
        <TableCell>
          {exit_datetime
            ? dateTimeFormatExact(exit_datetime, 'DD-MM-yyyy HH:mm:ss')
            : '-'}
        </TableCell>
        <TableCell>{getAmountMoney()}</TableCell>
        <TableCell>{debt_amount ? formatMoney(debt_amount) : '-'}</TableCell>
        {/* <TableCell>{valet ? 'Valet' : '-'}</TableCell> */}
        <TableCell>{paymentStatuses[order.result] || 'В процессе'}</TableCell>
        {/* <TableCell>{chargedFromEfsBalance}</TableCell>*/}
        <TableCell>{discount_type}</TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {p3_entry_at && moment(p3_entry_at).isValid()
            ? moment(p3_entry_at).format('DD MMM HH:mm')
            : p3_entry
            ? 'Да'
            : 'Отсутствует'}
        </TableCell>
        <TableCell>
          <div
            style={{
              padding: 4,
              display: 'flex',
              // flexDirection: 'column',
              gap: 4,
            }}
          >
            {/* <span>{efs_visit ? 'Да' : 'Нет'}</span>*/}
            {/* {moment(efs_visit_at).isValid() && (*/}
            {/*  <span> - {moment(efs_visit_at).format('DD MMM HH:mm')}</span>*/}
            {/* )}*/}
            <span>
              {/* eslint-disable-next-line no-nested-ternary */}
              {efs_visit_at && moment(efs_visit_at).isValid()
                ? moment(efs_visit_at).format('DD MMM HH:mm')
                : efs_visit
                ? 'Да'
                : 'Отсутствует'}
            </span>
          </div>
        </TableCell>
        <TableCell>
          {discount_details !== null ? discount_details.total_hours : ''}
        </TableCell>
        <CanView permission={canUpdateParking}>
          <TableCell>
            <Grid container wrap="nowrap">
              <Tooltip title="Поставить/изменить время выезда" placement="top">
                <IconButton onClick={openSimulateFinishDialog}>
                  <Payment />
                </IconButton>
              </Tooltip>
              <Tooltip title="Провести оплату" placement="top">
                <IconButton
                  onClick={() => handleSimulateFinish(reloadPaymentValues)}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>
          </TableCell>
        </CanView>
      </TableRow>
      {isDialogOpen && (
        <SimulateFinish
          disableBackdropClick
          disableEscapeKeyDown
          open={isDialogOpen}
          onClose={closeSimulateFinishDialog}
          onSubmit={handleSimulateFinish}
          style={{ display: 'flex' }}
          item={selectedItem}
        />
      )}
    </>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
